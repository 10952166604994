<template>
    <v-container>
        <notification-popup
            :dialog="notifFailed"
            :contentMsg="$t('FailedDoPayment')"
            :headerMsg="$t('Failed')"
            :nameBtnNo="$t('Cancel')"
            :nameBtnYes="$t('TryAgain')"
            colorIcon="danger"
            typeModal="transaction"
            Icon="mdi-close-circle"
            :onHandlerYes="onLoadAction"
            :onHandlerNo="onCancel"
        />
  
        <v-overlay :value="isLoading">
            <v-progress-circular
                :value="80"
                :size="70"
                :width="7"
                color="green"
                indeterminate
            ></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col cols="12" class="pa-0">
                <div id="iframe-container"></div>
            </v-col>
            <v-col cols="12">
                <v-btn
                    v-show="showMicroform"
                    type="submit"
                    id="submit-button"
                    rounded
                    block
                    class="text-capitalize primary"
                >
                    {{ $t("Pay") }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import NotificationPopup from "@/components/NotificationPopup.vue";
// import moment from "moment";
export default {
    components: { NotificationPopup },
    data() {
        return {
            microformSouceCode: "https://alepay-v3-sandbox.nganluong.vn/pay/microform/web/js/1.0/alepay-microform.js",
            targetOrigin: "https://alepay-v3-sandbox.nganluong.vn",
            cardRequestToken: "",
            env: "dev",
            showMicroform: false,
            sendOrderNotif: true,
            showRequestToken: true,
            showSendOrder: false,
            trx_Detail: null,
            isLoading: true,
            notifFailed: false
        };
    },
    methods: {
        onLoadAction() {
            this.notifFailed = false

            this.env = process.env.VUE_APP_ENV_VARIABLE;
            this.microformSouceCode = "https://alepay-v3-sandbox.nganluong.vn/pay/microform/web/js/1.0/alepay-microform.js"
            this.targetOrigin = window.location.origin

            this.trx_Detail = JSON.parse(localStorage.getItem("trxDetail"))
            
            const conversation_id = this.$helpers.uuidv4()

            let payload = {
                ep: "ticketpurchase",
                be_id: this.trx_Detail.id_business_entity,
                paymentChannelId: this.trx_Detail.payment_channel_id,
                amount: String(this.trx_Detail.gross_amount),
                conversation_id,
                reference_data: {
                    phone_number: this.trx_Detail.phone_number,
                    ticket_id: this.trx_Detail.order_id,
                    event_name: "Ticket Puchasing"
                }
            }
        
            this.$store.dispatch('orchestrator/requestPayment', payload)
                .then(async (response) => {
                    if (response.status) {
                        const paymentChannelId = response.data.paymentChannelId
                        const response_issuer = response.data.response_code
                        const issuer_resp = JSON.parse(window.atob(response.data.issuer_response))
                        
                        // await navigator.clipboard.writeText(JSON.stringify(issuer_resp))

                        const splitPayChannel = String(paymentChannelId).split('-')
                        if (paymentChannelId == 'momo-credit_debit' && response_issuer == '4476') {
                            window.location.replace(issuer_resp.payUrl);
                        } else if (splitPayChannel[2] == 'VA' && response_issuer == '4476') {
                            // Virtual Account logic here
                            const host = process.env.VUE_APP_WEB_CORE_PAYMENT
                            const path = 'virtual-account/' + issuer_resp.external_id
                            const url_virtual = host + path
                            
                            window.location.replace(url_virtual);
                        } else if (splitPayChannel[2] == 'ewallet' && String(splitPayChannel[1]).toLocaleLowerCase() != 'ovo') {
                            // E-Wallet logic here
                            window.location.replace(issuer_resp?.actions?.desktop_web_checkout_url);
                        } else {
                            this.$router.replace({ name: "PaymentStatus", params: { payment_token: this.trx_Detail.order_id }, });
                        }
                    } else {
                        this.$router.replace({ name: "PaymentStatus", params: { payment_token: this.trx_Detail.order_id }, });
                    }
                    this.isLoading = false
                })
                .catch(err => {
                    console.log(err)
                    // this.notifFailed = true
                })
        },
        onCancel() {
            this.$router.go(-1)
        }
    },
    created() {
        this.onLoadAction()
    },
    mounted() {
        let externalScript = document.createElement("script");
        externalScript.setAttribute("src", "https://alepay-v3-sandbox.nganluong.vn/pay/microform/web/js/1.0/alepay-microform.js");
        document.head.appendChild(externalScript);
    },
};
</script>
  
<style scoped>
#iframe-container {
    height: 500px;
}
</style>